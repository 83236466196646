import ApiService from "@/core/services/api.service";

export const GET_ALL_FILTERS = () => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.get(`/segments/all-filters`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const GET_SEGMENTS = (currentPage, perPage) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`/segments`, {
      per_page: perPage,
      current_page: currentPage,
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const GET_SEGMENT = (id, currentPage, search) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`/segments/${id}`, {
      search: search,
      current_page: currentPage,
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const GET_BROADCAST = (id) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`/broadcast/${id}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const CREATE_SEGMENT = (payload) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post(`/segments`, payload)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const CHANGE_SEGMENTS_STATUS = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.patch(`/segments/status`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const CHANGE_SEGMENTS_CUSTOMER_STATUS = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.patch(`segments/change-customer-status`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const DELETE_SEGMENTS = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.patch(`/segments/delete`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const DELETE_SEGMENTS_CUSTOMERS = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.patch(`/segments/contact-delete`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const GET_BROADCAST_OPTIONS = () => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.get(`broadcast/get-option`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const GET_BROADCASTS = (currentPage, selectstatus, search) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`/list-employee`, {
      current_page: currentPage,
      selectstatus: selectstatus,
      search,
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const CREATE_BROADCAST = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post(`/broadcast`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const GET_WHATSAPP_OPTION_API = () => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.get(`/whatsapp/template/get-option`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const GET_HEADER_HANDLER = (body) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post(`whatsapp/template/get-header-handle`, body)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const CREATE_TEMPLATE = (type, body) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post(`whatsapp/template/${type}`, body)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const UPLOAD_TEMPLATE_FILE = (body) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post(`upload-file`, body)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const GET_TEMPLATE = (id) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.get(`/whatsapp/template?id=${id}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const GET_ALL_TEMPLATE = (currentPage) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.get(`whatsapp/template/listing?current_page=${currentPage}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const DELETE_TEMPLATE = (id, type) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.delete(`whatsapp/template/delete?id=${id}&type=${type}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const TEST_BROADCAST = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post(`broadcast/blast-test`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const BlAST_BROADCAST = (id) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post(`broadcast/blast?broadcast=${id}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_DEPARTMENTPRODUCT = () => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`/list-creport`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_DEPARTMENTTOTAL = (id, department_id) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.patch(`get-product-budget-list`, {
      id,
      department_id,
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_REPORT = (department_id, delivery_date) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`/get-product-department-list`, {
      department_id,
      delivery_date,
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_SALARYS = (currentPage,year) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`/list-month-salary`, {
      current_page: currentPage,
            year
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
